import * as React from 'react'


export default function LoginStatus({global, dispatch}) {

  if (!global.current_user_vuid) {
    return null
  }


  function handleSignOutClick() {
    dispatch({
      type: 'UI_LOGOUT',
      data: {},
    })
  }


  return (
    <div
      style={{
        display: 'block',
        fontSize: '0.8em',
        textAlign: 'right',
      }}
    >
      Signed in as {global.current_user_first_name}

      <br />

      <a href='#' onClick={handleSignOutClick}>
        Sign Out
      </a>
    </div>
  )
}
